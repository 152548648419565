import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
// import FaqImage from "../assets/images/faq.svg";
import FreelanceImage from "../assets/images/freelance.svg";
import StartCompanyImage from "../assets/images/start-company.svg";
import Button from "../components/Button/Button";
import StartYourBusiness from "../assets/images/start-your-business.svg";
import Heading from "../components/Heading";
import {Link} from "gatsby";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import FaqIcon from "../assets/images/faq.svg";
import Faqs from "../components/Faqs";

type SroProps = {
};

const offers = [
    {
        title: 'Contact us',
        text: 'Fillout the form and we will get back to you with all important information. We walk you through the whole process and discuss your options.',
    },
    {
        title: 'Payment',
        text: 'You no longer need stamps - choose your online payment method. After paying the fee, we will process your application for the opening of a trade, and you will be able to do business on the second working day. You will receive your trade license by e-mail in electronic form within approximately three working days.',
    },
    {
        title: 'Send documents',
        text: 'After creating an order to establish a trade, we will immediately send you an email with a completed application. All you have to do is sign it, scan it and send it back to us. And if you have an ID card with a chip, you don\'t even have to print.',
    },
    {
        title: 'Company registration',
        text: 'We will not only register your trade at the trade licensing office, but we will also request a VAT number and we will also notify your health insurance company of the establishment of the business. No extra charge.',
    },
];

const Freelance: FunctionComponent<SroProps> = (props) => {

    return (
        <>
            <Section className='py-8' fullWidth>
                <div>
                    <Hero
                        headline={"Živnosť — Freelance"}
                        subheadline={"Don't waste time or money and start a business online! Starting a business online without having to spend long hours in offices and at least half the price of starting a business in the traditional way."}
                        heroImage={FreelanceImage}
                        buttons={(
                            <div>
                                <Link to={'/order-form'}>
                                    <Button>
                                        Setup your S. R. O.
                                    </Button>
                                </Link>
                                <Link to={'#offer'}>
                                    <Button className={'ml-4'} variant={'border'}>
                                        Learn more
                                    </Button>
                                </Link>
                            </div>
                        )}
                        outerContainer
                    />
                </div>
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading id="offer" type='h3' className={'text-ink-40'}>Our offer is simple —</Heading>
                    <Heading type={'h2'}>
                        We handle everything for you.
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-8 mb-12'}>
                    {offers.map((offer, index) => (
                        <div className={'flex'}>
                            <div className={'flex w-1/5 justify-center mr-4 items-start'}>
                                <h3 className={'font-sans text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-brand-100 to-white'}>
                                    {index+1}
                                </h3>
                            </div>
                            <div className={'w-2/3'}>
                                <Heading type="h5" className={'font-semibold'}>{offer.title}</Heading>
                                <p className={'text-ink-60 mt-2'}>{offer.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-ink-5'}>
                <div className="grid grid-cols-2 gap-x-16">
                    <div>
                        <p className={'font-caveat text-2xl font-bold text-brand-100'}>Starts at 39 € </p>
                        <p className={'font-caveat text-xl font-bold text-ink-40 mb-3'}>+ 150 €  State fee</p>
                        <Heading type='h2' className={'mb-8'}>How much do you pay for creating a freelance company?</Heading>
                        <Link to={'/order-form'}>
                            <Button variant="secondary">Start Freelancing</Button>
                        </Link>
                    </div>
                    <div className={'ml-8'}>
                        <img src={StartCompanyImage} alt=""/>
                    </div>
                </div>
                <div className={'border-t border-ink-20 mt-16 py-6'}>
                    <p className={'text-sm text-ink-40'}>The price includes the preparation of sample documents for the proposal for registration of the company in the commercial register, for the tax office and trade license office, the fee for the business register, any number of free trades, costs of founding a company and professional administrative equipment. The price includes VAT, is final and without hidden fees.</p>
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex mb-16'}>
                    <div className={'w-full text-center'}>
                        <Heading type='h3'>Fast  & Easy —</Heading>
                        <Heading type='h2'>Do it online without visiting single institution. </Heading>
                    </div>
                </div>
                <div className={'grid grid-cols-1 lg:grid-cols-3 gap-24 text-white'}>
                    {Array(6).fill().map(() => (
                        <div>
                            <Heading type='h5' className={'font-semibold'}>Registration with authorities</Heading>
                            <p className={'text-ink-10'}>It usually takes 3-5 business days for state to process your request. After that, you will be provided with all documents that confirm your business in Slovakia.</p>
                        </div>
                    ))}
                </div>
                <div className={'text-center mt-16'}>
                    <Button variant="secondary">Start your business</Button>
                </div>

            </Section>
            <Section className={'py-20'}>
                <div className={'flex mb-16 flex-wrap justify-center'}>
                    <div className={'w-full lg:w-2/5 flex justify-center items-start lg:order-last mb-10 lg:mb-0'}>
                        <img src={FaqIcon} alt="" className={'w-full max-w-lg'}/>
                    </div>
                    <div className={'w-full lg:w-3/5'}>
                        <Heading type='h3' className={'text-ink-40'}>Frequently asked —</Heading>
                        <Heading type='h2'>Questions & Answers</Heading>
                        <div className={'py-12 sm:py-16'}>
                            <Faqs items={[]}/>
                        </div>
                        <Button variant="border">Show all</Button>
                    </div>
                </div>
            </Section>
            <FeaturedPostsSection/>
            <Section className={'py-20 bg-black text-white'}>
                <div className={'mb-12'}>
                    <Heading type='h2' className={'text-center font-bold max-w-3xl mx-auto'}>Stop wasting your time and money. Start your S.R.O. business today.</Heading>
                </div>
                <div className={'flex justify-center items-center flex-col md:flex-row gap-8'}>
                    <Button variant="secondary">Get started now</Button>
                    <Button variant="border">Contact us</Button>
                </div>
            </Section>
        </>
    );
};

export default Freelance;
